<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        게임 기본설정
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <b-form @submit.prevent>
        <b-row v-if="type !== 'minigame'">
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="유저배팅 취소시간 (분)"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchGameSetBasic"
                id="site-name"
                :value="fetchGameSetBasic.userBetCancelTime"
                class="font-small-3"
                size="sm"
                placeholder="0"
                style="color:rgb(229,166,48); text-align: right"
                @change="updateParam('fetchGameSetBasic', 'userBetCancelTime', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="배팅취소 마감시간 (분)"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchGameSetBasic"
                id="site-name"
                :value="fetchGameSetBasic.gameBetCancelTime"
                class="font-small-3"
                size="sm"
                placeholder="0"
                style="color:rgb(229,166,48); text-align: right"
                @change="updateParam('fetchGameSetBasic', 'gameBetCancelTime', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="고액배팅 알람기준 (원)"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchGameSetBasic"
                id="site-name"
                :value="fetchGameSetBasic.userBetHighAlarm | formatNumber"
                type="text"
                class="font-small-3"
                size="sm"
                placeholder="0"
                style="color:rgb(229,166,48); text-align: right"
                @change="updateParam('fetchGameSetBasic', 'userBetHighAlarm', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            id="basic1-btn"
            class="mt-1"
            cols="12"
          >
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 w-auto content-right"
              size="sm"
              @click="submit('updateGameSetBasic')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="배팅 마감 시간 (초)"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchGameSetBasic"
                id="site-name"
                :value="fetchGameSetBasic.betTimeCloseMini"
                class="font-small-3"
                size="sm"
                placeholder="0"
                style="color:rgb(229,166,48); text-align: right"
                @change="updateParam('fetchGameSetBasic', 'betTimeCloseMini', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="고액배팅 알람기준 (원)"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchGameSetBasic"
                id="site-name"
                :value="fetchGameSetBasic.userBetHighAlarmMini | formatNumber"
                type="text"
                class="font-small-3"
                size="sm"
                placeholder="0"
                style="color:rgb(229,166,48); text-align: right"
                @change="updateParam('fetchGameSetBasic', 'userBetHighAlarmMini', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col
            id="basic1-btn"
            class="mt-1"
            cols="12"
          >
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1 w-auto content-right"
              size="sm"
              @click="submit('updateGameSetBasic')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BBadge,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_BASIC } from '@/store/game/action'
import { UPDATE_GAME_SET_BASIC } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabBasic',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BBadge,
    BFormGroup,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetBasic: 'fetchGameSetBasic',
      updateGameSetBasic: 'updateGameSetBasic',
    }),
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetBasic: FETCH_GAME_SET_BASIC,
      $updateGameSetBasic: UPDATE_GAME_SET_BASIC,
    }),
    updateParam(t, e, v) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = { t, e, v }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateParam', events)
      // console.log(this.$store.mainNotice('settingsStore/mainNotice'))
    },
    async fetchData() {
      const mySite = this.site
      await this.$fetchGameSetBasic({
        site: mySite,
      })
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
          // console.log(result.isConfirmed)
          // console.log(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetBasic') {
          await this.$updateGameSetBasic({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
