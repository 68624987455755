<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        배당상세설정
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          class="mr-1"
          style="float: right"
          size="sm"
          @click="resetOddsData"
        >
          초기화
        </b-button>
      </h4>
      <hr>
      <b-table
        responsive
        show-empty
        small
        striped
        empty-text="No matching records found"
        :items="fetchGameSetOddsCustom"
        :fields="this.type !== 'minigame' ? fields : fields2"
        style="text-align: center !important; font-size: small"
      >
        <template #cell(no)="data">
          <div
            class=""
            style="min-width: 1rem"
          >
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(type)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.type }}
          </div>
        </template>
        <template #cell(menuId)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.menuId }}
          </div>
        </template>
        <template #cell(menuName)="data">
          <div
            class=""
            style="min-width: 3rem"
          >
            {{ data.item.menuName }}
          </div>
        </template>
        <template #cell(oddsType)="data">
          <div
            class=""
            style="min-width: 4rem"
          >
            {{ data.item.oddsType }}
          </div>
        </template>
        <template #cell(oddsTypeName)="data">
          <div
            class=""
            style="min-width: 4rem"
          >
            {{ data.item.oddsTypeName }}
          </div>
        </template>
        <template #cell(oddsAddHome)="data">
          <div class="d-flex justify-content-center">
            <b-form-input
              id="oddsAddHome"
              :value="data.item.oddsAddHome"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddHome', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsAddDraw)="data">
          <div
            class="d-flex justify-content-center"
          >
            <b-form-input
              v-if="data.item.itemName === 'soccer' && data.item.oddsType === 'Match Winner' || data.item.type === 'minigame'"
              id="oddsAddDraw"
              :value="data.item.oddsAddDraw"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddDraw', $event, data.index)"
            />
            <b-form-input
              v-else
              disabled
              id="oddsAddDraw"
              :value="data.item.oddsAddDraw"
              style="color: gray; text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddDraw', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsAddAway)="data">
          <div class="d-flex justify-content-center">
            <b-form-input
              id="oddsAddAway"
              :value="data.item.oddsAddAway"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddAway', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsAddStraight)="data">
          <div class="d-flex justify-content-center" v-if="data.item.type !== 'minigame'">
            <b-form-input
              id="oddsAddStraight"
              :value="data.item.oddsAddStraight"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddStraight', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsAddUnderDog)="data">
          <div class="d-flex justify-content-center" v-if="data.item.type !== 'minigame'">
            <b-form-input
              id="oddsAddUnderDog"
              :value="data.item.oddsAddUnderDog"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsAddUnderDog', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsMinInvisible)="data">
          <div class="d-flex justify-content-center" v-if="data.item.type !== 'minigame'">
            <b-form-input
              id="oddsMinInvisible"
              :value="data.item.oddsMinInvisible"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsMinInvisible', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(oddsMaxInvisible)="data">
          <div class="d-flex justify-content-center" v-if="data.item.type !== 'minigame'">
            <b-form-input
              id="oddsMaxInvisible"
              :value="data.item.oddsMaxInvisible"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('fetchGameSetOddsCustom','oddsMaxInvisible', $event, data.index)"
            />
          </div>
        </template>
      </b-table>
      <b-col
        id="basic1-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="submit('updateGameSetOddsCustom')"
        >
          저장
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BButton,
  BBadge,
  BTable,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_ODDS_CUSTOM } from '@/store/game/action'
import { UPDATE_GAME_SET_ODDS_CUSTOM } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabOddsCustom',
  components: {
    BCard,
    BFormInput,
    BButton,
    BBadge,
    BTable,
    BCol,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      fields: [
        {
          key: 'no', sortable: false, label: 'No.',
        },
        {
          key: 'type', sortable: false, label: '타입',
        },
        {
          key: 'menuId', sortable: false, label: 'Code',
        },
        {
          key: 'menuName', sortable: false, label: '메뉴명',
        },
        {
          key: 'itemId', sortable: false, label: 'Code',
        },
        {
          key: 'itemName', sortable: false, label: '종목명',
        },
        {
          key: 'oddsType', sortable: false, label: '유형',
        },
        {
          key: 'oddsTypeName', sortable: false, label: '유형명',
        },
        {
          key: 'oddsAddHome', sortable: false, label: '홈팀 보정배당',
        },
        {
          key: 'oddsAddDraw', sortable: false, label: '무승부 보정배당',
        },
        {
          key: 'oddsAddAway', sortable: false, label: '원정팀 보정배당',
        },
        {
          key: 'oddsAddStraight', sortable: false, label: '정배 보정배당',
        },
        {
          key: 'oddsAddUnderDog', sortable: false, label: '역배 보정배당',
        },
        {
          key: 'oddsMinInvisible', sortable: false, label: '최소 노출배당',
        },
        {
          key: 'oddsMaxInvisible', sortable: false, label: '최대 노출배당',
        },
      ],
      fields2: [
        {
          key: 'no', sortable: false, label: 'No.',
        },
        {
          key: 'type', sortable: false, label: '타입',
        },
        {
          key: 'menuId', sortable: false, label: 'Code',
        },
        {
          key: 'menuName', sortable: false, label: '메뉴명',
        },
        {
          key: 'itemId', sortable: false, label: 'Code',
        },
        {
          key: 'itemName', sortable: false, label: '종목명',
        },
        {
          key: 'oddsType', sortable: false, label: '유형',
        },
        {
          key: 'oddsTypeName', sortable: false, label: '유형명',
        },
        {
          key: 'oddsAddHome', sortable: false, label: '홈팀 보정배당',
        },
        {
          key: 'oddsAddDraw', sortable: false, label: '무승부 보정배당',
        },
        {
          key: 'oddsAddAway', sortable: false, label: '원정팀 보정배당',
        },
      ],
    }
  },
  computed: {
    ...gameStore.mapGetters({
      fetchGameSetOddsCustom: 'fetchGameSetOddsCustom',
      updateGameSetOddsCustom: 'updateGameSetOddsCustom',
    }),
  },
  created() {
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('authSiteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetOddsCustom: FETCH_GAME_SET_ODDS_CUSTOM,
      $updateGameSetOddsCustom: UPDATE_GAME_SET_ODDS_CUSTOM,
    }),
    updateParam(t, e, v, i) {
      // console.log(t)
      // console.log(f)
      // console.log(e)
      // console.log(v)
      // console.log(i)
      const events = {
        t, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyParam', events)
    },
    async fetchData() {
      const mySite = this.site
      await this.$fetchGameSetOddsCustom({
        site: mySite,
        type: this.type,
      })
      // console.log(this.fetchGameSetMenu)
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetOddsCustom') {
          await this.$updateGameSetOddsCustom({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    resetOddsData() {
      this.fetchGameSetOddsCustom.forEach((item, index) => {
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsAddHome', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsAddDraw', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsAddAway', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsAddStraight', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsAddUnderDog', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsMinInvisible', 0)
        this.$set(this.fetchGameSetOddsCustom[index], 'oddsMaxInvisible', 0)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
